<template>
  <product-step-wrapper
    v-if="showQuestions"
    :questions="surveillanceQuestions"
    :default-model="defaultModel"
    :submit-function="saveData"
    :on-success="onSubmit"
    skip-intro
  />
  <th-card v-else>
    <div class="th-py-8">
      <h5 class="th-m-0 body2">
        Ar bendrovės patalpų viduje ir/ar bendrovės teritorijoje naudojamos
        vaizdo kameros?
      </h5>
      <radio-input
        :input="cameraSurveillance"
        v-model="defaultModel.company_surveillance"
      />
    </div>
    <div class="th-py-8">
      <h5 class="th-m-0 body2">
        Ar įrašinėjate jūsų bendrovei skambinančių asmenų pokalbius telefonu?
      </h5>
      <radio-input
        :input="cameraSurveillance"
        v-model="defaultModel.company_surveillance_phone_recording"
      />
    </div>
    <div class="text-right">
      <v-btn color="primary" @click="next" class="th-mt-24" :loading="loading">
        {{ noSurveillance ? $translation.t("submit") : $translation.t("next") }}
      </v-btn>
    </div>
  </th-card>
</template>

<script>
import ProductStepWrapper from "./ProductStepWrapper";
import RadioInput from "../../../components/FormFields/RadioInput";
import ThCard from "../../../components/GeneralComponents/ThCard";
import {
  conditionalResult,
  isFalse,
} from "../../../utils/questionGeneratorHelpers";
import { mapMutations } from "vuex";
import defaultModel from "../../../data/questionnaire/business/businessSurveillance";
import { questionByType } from "../../../utils/service/Questionnaire";

const QUESTIONS_TYPE = "surveillance";

export default {
  name: "SurveillanceQuestions",
  components: { ThCard, RadioInput, ProductStepWrapper },
  props: {
    value: {},
    saveData: {
      type: Function,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      questions: questionByType(QUESTIONS_TYPE, this.$route.params.locale),
      defaultModel,
      showQuestions: false,
      loading: false,
    };
  },
  async beforeCreate() {
    this.$store.commit("questionnaire/RESET_CURRENT_QUESTION");
  },
  async created() {
    this.defaultModel = this.value || this.defaultModel;

    this.SET_QUESTIONS([]);

    this.$route.params.type = QUESTIONS_TYPE;

    this.$set(
      this.defaultModel,
      "company_surveillance",
      this.defaultModel?.company_surveillance ? "true" : "false"
    );

    this.$set(
      this.defaultModel,
      "company_surveillance_phone_recording",
      this.defaultModel?.company_surveillance_phone_recording ? "true" : "false"
    );

    this.questions.forEach((q, qIndex) => {
      this.SET_CURRENT_QUESTION_STATUS({
        q: qIndex + 1,
        visited: true,
      });
    });
  },
  beforeDestroy() {
    this.SET_QUESTIONS(null);
  },
  computed: {
    cameraSurveillance() {
      return {
        default: "true",
        values: {
          true: "Taip",
          false: "Ne",
        },
      };
    },
    surveillanceQuestions() {
      return this.questions.filter((question) =>
        conditionalResult(question, this.defaultModel)
      );
    },
    noSurveillance() {
      return (
        isFalse(this.defaultModel.company_surveillance) &&
        isFalse(this.defaultModel.company_surveillance_phone_recording)
      );
    },
  },
  methods: {
    async next() {
      if (this.noSurveillance) {
        this.loading = true;
        await this.saveData(this.defaultModel);
        await this.onSubmit();
        this.loading = false;
      } else {
        this.SET_QUESTIONS(this.surveillanceQuestions);
        this.showQuestions = true;
      }
    },
    ...mapMutations("questionnaire", [
      "SET_QUESTIONS",
      "SET_CURRENT_QUESTION_STATUS",
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
