let data = {};

// if (process.env.NODE_ENV === "development")
//   data = {
//     company_surveillance: "true",
//     company_surveillance_camera_types: ["analoginės"],
//     company_surveillance_purpose: [
//       "į stebėjimo lauką patenka ir įmonei nepriklausianti   teritorija / gyvenamoji patalpa ir /arba jai priklausanti privati teritorija   ar įėjimas į ją",
//     ],
//     company_surveillance_purpose_other_people_agreement: "true",
//     company_surveillance_contacts: "true",
//     company_surveillance_car: "true",
//     company_surveillance_car_inside: "true",
//     company_surveillance_car_inside_knows: "true",
//     company_surveillance_documentation: "true",
//     company_surveillance_documentation_document: "Vaizdo stebejimo tvarka",
//     company_surveillance_storage_location: ["kompiuteryje"],
//     company_surveillance_storage_term: "2",
//     company_surveillance_access: ["vadovas"],
//     company_surveillance_access_employee_roles: [],
//     company_surveillance_access_third_party: [],
//     company_surveillance_access_protected: "true",
//     company_employee_surveillance_computers: "true",
//     company_surveillance_phone_recording: "true",
//     company_surveillance_phone_recording_informed: "true",
//     company_surveillance_phone_recording_storage_term: ["14 dienų"],
//     company_surveillance_phone_recording_storage: ["debesyse"],
//     company_surveillance_phone_recording_access: ["personalo vadovas"],
//   };

export default data;
